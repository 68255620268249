import React, { PureComponent, useEffect, useState } from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import axios from 'axios'
// axios
//     .get("https://tokien.online/api/content/statistics/monthly")
//     .then((response) => {
//         // Handle the successful response here

//     })
//     .catch((error) => {
//         // Handle any errors that occurred during the request
//         console.error("Error fetching data:", error);
//     });


const ContentList = ({ filterOptions }) => {
    const [contentData, setContentData] = useState([]);
    const [srcIdFilter, setSrcIdFilter] = useState("");

    const handleFilterChange = (event) => {
        fetchData();
        setSrcIdFilter(event.target.value);

    };

    let apiLink = `https://tokien.online/api/content/statistics/monlyForSources?sourceId=${srcIdFilter}`;
    console.log(apiLink)
    const fetchData = async () => {
        // Make an API request to fetch the data
        // console.log(`https://tokien.online/api/content/statistics/monlyForSources?sourceId=${srcIdFilter}`)
        const response = await axios.get(`https://tokien.online/api/content/statistics/monlyForSources?sourceId=${srcIdFilter}`);
        //console.log(response)
        setContentData(response)
    };



    console.log(contentData)




    return (
        <div>
            <h1>Content List With Sources</h1>
            <label htmlFor="srcIdFilter">Filter by Source:</label>
            <select
                id="srcIdFilter"
                name="srcIdFilter"
                onChange={handleFilterChange}
                value={srcIdFilter}
            >
                <option value="">english.dvb.no</option>
                {filterOptions.map((option) => (
                    <option key={option.id} value={option.id}>
                        {option.name}
                    </option>
                ))}
            </select>
            <BarChart
                width={700}
                height={400}
                data={contentData?.data?.doc}
                margin={{
                    top: 25,
                    right: 30,
                    left: 200,
                    bottom: 5
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="total" fill="#8884d8" />
            </BarChart>
        </div>
    );
};


const ContentList2 = ({ filterOptions }) => {
    const [contentData, setContentData] = useState([]);
    const [srcIdFilter, setSrcIdFilter] = useState("");

    const handleFilterChange = (event) => {
        fetchData();
        setSrcIdFilter(event.target.value);

    };

    let apiLink = `https://tokien.online/api/content/statistics/monlyForTopics?topicId=${srcIdFilter}`;
    console.log(apiLink)
    const fetchData = async () => {
        // Make an API request to fetch the data
        // console.log(`https://tokien.online/api/content/statistics/monlyForSources?sourceId=${srcIdFilter}`)
        const response = await axios.get(`https://tokien.online/api/content/statistics/monlyForTopics?topicId=${srcIdFilter}`);
        //console.log(response)
        setContentData(response)
    };
    console.log(contentData)
    return (
        <div>
            <h1>Content List With Topic</h1>
            <label htmlFor="srcIdFilter">Filter by Topic:</label>
            <select
                id="srcIdFilter"
                name="srcIdFilter"
                onChange={handleFilterChange}
                value={srcIdFilter}
            >
                <option value="">WEBSITE_ANY</option>
                {filterOptions.map((option) => (
                    <option key={option.id} value={option.id}>
                        {option.name}
                    </option>
                ))}
            </select>
            <BarChart
                width={700}
                height={400}
                data={contentData?.data?.doc}
                margin={{
                    top: 25,
                    right: 30,
                    left: 200,
                    bottom: 5
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="total" fill="#8884d8" />
            </BarChart>
        </div>
    );
};

export default function ChartDisplay() {
    const [data, setData] = useState();

    useEffect(() => {
        // Define the API endpoint URL
        const apiUrl = "https://tokien.online/api/content/statistics/monthly"; // Replace with your API URL

        // Make the Axios GET request
        axios
            .get(apiUrl)
            .then((response) => {
                // Handle the successful response here
                setData(response);
            })
            .catch((error) => {
                // Handle any errors that occurred during the request
                console.error("Error fetching data:", error);
            });
    }, []);
    console.log('response:', data?.data?.doc);
    const filterOptions = [{
        "id": "69171a47-ae94-4073-ad49-8b9e2fad3092",
        "name": "english.dvb.no",
    },
    {
        "id": "84c61770-b4a8-4a9a-a3fb-7b071d9392aa",
        "name": "mizzima.com",
    },
    {
        "id": "933f213e-ea6a-4216-a68a-72e635ac8536",
        "name": "irrawaddy.com"
    },
    {
        "id": "5cc48694-375d-4877-bc6d-ccdb20033873",
        "name": "nugmyanmar.org"
    },
    {
        "id": "a7d99103-8be9-4ac8-ba6a-266661a39bdb",
        "name": "narinjara.com"
    },
    {
        "id": "8a878fef-dcbf-47e7-adeb-22ad85d25bdd",
        "name": "kachinnews.com"
    },
    {
        "id": "04027ae3-05b9-405f-a930-85f994c62b31",
        "name": "mypilar.org"
    },
    {
        "id": "fd309450-f652-444a-b558-0a4644fb8a13",
        "name": "mmbiztoday.com"
    },
    {
        "id": "5481f1ec-eb54-433f-aac0-c2763d0a5331",
        "name": "gnlm.com.mm"
    },
    {
        "id": "05239252-2e3c-4f4b-a849-9a738d927597",
        "name": "moemaka.com"
    },
    {
        "id": "deae787b-853b-48cd-8305-0669388700e2",
        "name": "myanmargazette.net"
    },
    {
        "id": "0efa36e6-0cc5-473f-9f8c-5fd3ba1f0b63",
        "name": "gmsrmm.org"
    },
    {
        "id": "7040cd04-7c98-4fd5-bd14-c2d9b3d3ee0c",
        "name": "eng.moemaka.net"
    },
    {
        "id": "ae9cf4a9-f349-4979-9506-a56fbee8a293",
        "name": "mypilar.org"
    },
    {
        "id": "5a55a2af-9c1d-44b4-bda1-ad33711700d3",
        "name": "Kachin News - English"
    },
    {
        "id": "145789038832459",
        "name": "Myanmar / Burmese Community in USA"
    },
    {
        "id": "ba8093e8-c5cd-4e67-8d6e-f39684a569c0",
        "name": "Burma News International - English News"
    },
    {
        "id": "66660537-9245-4475-8361-2ccdeda23289",
        "name": "www.rfa.org"
    },
    {
        "id": "9e2cca27-f88c-4fd2-83ed-2c9ba2aa009d",
        "name": "www.moi.gov.mm"
    },
    {
        "id": "b39dae82-2bcb-4d01-9b3c-7c2c336f24aa",
        "name": "www.bbc.com",
    }
    ]
        // Add more options as needed
    const filterOptions2 = [
        {"id": "7082d19c-c41b-454c-9578-ee18820361f0",
        "name": "National Unity Government",},
        {"id": "b135b315-4127-47b2-ba38-6a373d0abdbd",
        "name": "Myanmar Junta",},
        {"id": "38849b61-98ac-4800-aff5-e76011457838",
        "name": "Test crawl",},
        {"id": "cdc8dd73-65b8-4e93-bc03-3dea0219d622",
        "name": "Spring Revolution",},
        {"id": "43548192-3bb6-42ec-ac40-57823e673993",
        "name": "Coup d'etat"},
        {"id": "d0414f86-3721-479f-a7a5-f75294c05ba3",
        "name": "WEBSITE_ANY",},
        {"id": "b7ccfb56-ac5c-45b7-bf8c-ecece80bd7a8",
        "name": "Test (25/06)"}
    ];
    return (
        <div><div className="App">
            <ContentList filterOptions={filterOptions} />
        </div>
            <div className="App2">
                <ContentList2 filterOptions={filterOptions2} />
            </div>
            <div>
                <h1>Total Content Each Day On This Month</h1>
                <BarChart
                    width={700}
                    height={400}
                    data={data?.data?.doc}
                    margin={{
                        top: 25,
                        right: 30,
                        left: 200,
                        bottom: 5
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="total" fill="#8884d8" />
                </BarChart>
            </div>
        </div>
    );
}